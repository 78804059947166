@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Calistoga&family=Poppins&display=swap");

html,
body {
  scrollbar-width: none;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.calistoga-regular {
  font-family: "Calistoga", serif;
  font-weight: 400;
  font-style: normal;
}
